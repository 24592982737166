<template>
  <div>
    <van-empty class="empty" image="error" description="404-资源不存在" />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.empty{
  margin: 12px;
}
</style>